import React from 'react'
import Heading from '../components/UI/Heading'
import AppLayout from '../components/layouts/App'

const NotFoundPage = ({
    location
}) => {
    return (
        <AppLayout location={location} name="" description="" primaryImageOfPage={null}>
            <Heading>Page not found</Heading>
        </AppLayout>
    )
}

export default NotFoundPage