import React from "react"
import { ThemeProvider } from '@emotion/react'
import { IconContext } from "react-icons"
import GlobalStyle from '../../styles'
import usePaths from "../../hooks/usePaths"
import "../../fonts"
import theme from "../../theme"
import SEO from "../seo"

const AppLayout = ({
    name,
    description,
    primaryImageOfPage,
    location,
    children
}) => {
    const { isRootPath } = usePaths(location)

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />

            <SEO
                isRootPath={isRootPath}
                title={name}
                description={description}
                schema={{
                    type: 'WebPage',
                    name,
                    description,
                    primaryImageOfPage
                }}
            />

            <IconContext.Provider value={{}}>
                <div className="fh" data-is-root-path={isRootPath}>
                    {children}
                </div>
            </IconContext.Provider>
        </ThemeProvider>
    )
}

export default AppLayout
